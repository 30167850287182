<template>
  <b-modal
    id="hosting-transfer-modal"
    ref="hosting-transfer-modal"
    v-model="value"
    v-b-modal.modal-prevent-closing
    no-close-on-backdrop
    modal-class="hosting-transfer-modal"
    centered
    size="lg"
    hide-footer
  >
    <template #modal-header>
      <div class="ml-2 mr-auto my-auto">Transfer Hosting</div>
      <div class="icon-container close-icon ml-auto mr-2" @click="closeModal()">
        <font-awesome-svg
          style="cursor: pointer"
          :type="'circle-xmark'"
          :fill="'white'"
          :width="25"
          :height="25"
        />
      </div>
    </template>
    <div style="text-align: center; font-size: 18px">
      Please provide the login credentials for your current hosting provider account.
    </div>
    <ul class="mt-1 ml-4" style="font-style: italic; color: dimgray">
      <li>
        This step is required to migrate your website's files to our servers.
      </li>
      <li>
        Access to files at your current host is required for a smooth transfer.
      </li>
      <li>
        Common interfaces like WordPress or cPanel allow this access.
      </li>
    </ul>
    <div class="ml-4">
      Have questions? Give us a call: <span v-phone-number="phone()" />
    </div>
    <hr>
    <hosting-transfer-form
      :cancellable="true"
      :bus="bus"
      @closeModal="closeModal"
    />
  </b-modal>
</template>


<script>

import { phoneNumber } from '@/directives/directives'
export default {
  name: 'HostingTransferModal',
  components: {
    FontAwesomeSvg:      () => import('@/components/shared/FontAwesomeSvg.vue'),
    HostingTransferForm: () => import('@/components/Services/Hosting/HostingTransferForm.vue'),
  },
  directives: { phoneNumber },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      website: this.$store.getters['website/website'],
    }
  },
  methods: {
    closeModal() {
      this.$emit('input', false)
    },
    phone() {
      if (!this.website || !Array.isArray(this.website.phone_numbers)) {
        return null
      }

      return (this.website.phone_numbers.find((a) => a.kind === 'billing') || {}).phone_number
    },
  },
}
</script>


<style lang="scss" scoped>
.hosting-transfer-modal {
}
</style>
