var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.modal-prevent-closing",
          modifiers: { "modal-prevent-closing": true },
        },
      ],
      ref: "hosting-transfer-modal",
      attrs: {
        id: "hosting-transfer-modal",
        "no-close-on-backdrop": "",
        "modal-class": "hosting-transfer-modal",
        centered: "",
        size: "lg",
        "hide-footer": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function () {
            return [
              _c("div", { staticClass: "ml-2 mr-auto my-auto" }, [
                _vm._v("Transfer Hosting"),
              ]),
              _c(
                "div",
                {
                  staticClass: "icon-container close-icon ml-auto mr-2",
                  on: {
                    click: function ($event) {
                      return _vm.closeModal()
                    },
                  },
                },
                [
                  _c("font-awesome-svg", {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      type: "circle-xmark",
                      fill: "white",
                      width: 25,
                      height: 25,
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "center", "font-size": "18px" } },
        [
          _vm._v(
            "\n    Please provide the login credentials for your current hosting provider account.\n  "
          ),
        ]
      ),
      _c(
        "ul",
        {
          staticClass: "mt-1 ml-4",
          staticStyle: { "font-style": "italic", color: "dimgray" },
        },
        [
          _c("li", [
            _vm._v(
              "\n      This step is required to migrate your website's files to our servers.\n    "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n      Access to files at your current host is required for a smooth transfer.\n    "
            ),
          ]),
          _c("li", [
            _vm._v(
              "\n      Common interfaces like WordPress or cPanel allow this access.\n    "
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "ml-4" }, [
        _vm._v("\n    Have questions? Give us a call: "),
        _c("span", {
          directives: [
            {
              name: "phone-number",
              rawName: "v-phone-number",
              value: _vm.phone(),
              expression: "phone()",
            },
          ],
        }),
      ]),
      _c("hr"),
      _c("hosting-transfer-form", {
        attrs: { cancellable: true, bus: _vm.bus },
        on: { closeModal: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }